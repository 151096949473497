jQuery(document).ready(function() {
    "use strict"

    /*
    jQuery('.gallery').isotope({
        itemSelector: '.gallery-item',
        layoutMode: 'masonry'
    });

    jQuery('.gallery').imagesLoaded().progress(function() {
        jQuery('.gallery').isotope('layout');
    });
    */
});