jQuery(document).ready(function() {
    "use strict"

    //Carousel
    jQuery('.carousel').each(function(index) {
        var interval = Math.floor(Math.random() * 4750) + 3750;
        jQuery(this).carousel({
            interval: interval,
            pause: ""
        });
    });
});