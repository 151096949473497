jQuery(document).ready(function() {
    "use strict"

    jQuery("#back-to-top").click(function() {
        jQuery("html, body").animate({ scrollTop: 0 }, 1000);
    });
    jQuery(window).scroll(function() {
        if (isScrolledIntoView('#page-top')) {
			jQuery("#back-to-top").stop().fadeOut('fast');
        } else {
        	jQuery("#back-to-top").stop().fadeIn('fast');
        }
    });
});

function isScrolledIntoView(elem) {
    var docViewTop = jQuery(window).scrollTop();
    var docViewBottom = docViewTop + jQuery(window).height();

    var elemTop = jQuery(elem).offset().top;
    var elemBottom = elemTop + jQuery(elem).height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}