jQuery(document).ready(function() {
    "use strict"

    jQuery('.post-share-link').on('click', function() {
        if (jQuery(this).hasClass('facebook')) {
            window.open('http://www.facebook.com/sharer.php?u=' + location.href, '_blank');
        }
        if (jQuery(this).hasClass('twitter')) {
            window.open('https://twitter.com/intent/tweet?text=' + document.getElementsByTagName("title")[0].innerHTML + '&url=' + location.href + '&via=EmploymentAssessmentCentre', '_blank');
        }
        if (jQuery(this).hasClass('google-plus')) {
            window.open('https://plus.google.com/share?url=' + location.href, '_blank');
        }
        if (jQuery(this).hasClass('linkedin')) {
            window.open('https://www.linkedin.com/shareArticle?title=' + document.getElementsByTagName("title")[0].innerHTML + '&url=' + location.href, '_blank');
        }
        if (jQuery(this).hasClass('email')) {
            window.open('mailto:?subject=' + document.getElementsByTagName("title")[0].innerHTML + '&body=' + location.href, '_self');
        }
    });
});