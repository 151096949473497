jQuery(document).ready(function() {
    "use strict"
    jQuery('.container-grid').each(function(index) {
        jQuery(this).infiniteScroll({
            path: '.next',
            append: '.grid-row',
            scrollThreshold: 600,
			checkLastPage: true,
			history: false
        });
    });
});