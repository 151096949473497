jQuery(document).ready(function() {
    "use strict"
    /*
    if (jQuery('.next').length) {
        jQuery('.collection .list').infiniteScroll({
            path: '.next',
            append: '.collection .list-item',
            history: false,
        });
    }
    */
});