jQuery(document).ready(function() {
    "use strict"

    jQuery('#menu-main-menu i.fa').each(function(i) {
		jQuery(this).parent().attr('target', '_blank');
	});
	jQuery('#mobile-menu i.fa').each(function(i) {
		jQuery(this).closest('li').addClass('uk-hidden');
	});

});