jQuery(document).ready(function() {
    "use strict"
    var isTouchDevice = 'ontouchstart' in document.documentElement;
    if (!isTouchDevice) {
        jQuery('.excerpt.card.theme-default, .excerpt.card.theme-mephisto').on('mouseenter', function() {
            jQuery(this).find('.excerpt-content').stop(true, true).slideDown();
        });
        jQuery('.excerpt.card.theme-default, .excerpt.card.theme-mephisto').on('mouseleave', function() {
            jQuery(this).find('.excerpt-content').stop(true, true).slideUp();
        });
    }
});